import React from "react"
import Markdown from "react-markdown"
import { graphql } from "gatsby"

import LayoutPage from "../components/layout-page"
import SEO from "../components/seo"
import ImageMeta from "../components/ImageMeta"

const FAQQuestion = ({ question, answer }) => (
  <div className="col-lg-6 mt-45 block">
    <div className="f-heading medium f-18 title">
      <Markdown source={question.question} />
    </div>
    <div className="mt-10 color-heading text-adaptive">
      <Markdown source={answer.answer} />
    </div>
  </div>
)

export const FAQGroup = ({ questions, title }) => (
  <section className="content_19 bg-light pt-35 pb-35">
    <div className="container px-xl-0">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-11">
          {title ? (
            <h2 className="small text-left text-lg-center" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
              {title}
            </h2>
          ) : (
            undefined
          )}
          <div className="row justify-content-center justify-content-lg-between">
            {(questions || [])
              .sort((a, b) => a.order - b.order)
              .map(q => (
                <FAQQuestion key={q.id} {...q} />
              ))}
          </div>
        </div>
      </div>
    </div>
  </section>
)

const FAQPage = ({ data }) => (
  <LayoutPage>
    <SEO title={"Frequently Asked Questions"} meta={ImageMeta} />
    <div className={"pt-25"}>&nbsp;</div>
    {data.groups.nodes
      .filter(g => g.questions)
      .map(g => (
        <FAQGroup key={g.id} {...g} />
      ))}
  </LayoutPage>
)

export default FAQPage

export const query = graphql`
  query {
    groups: allContentfulFaqGroup(sort: { order: [ASC], fields: [order] }) {
      nodes {
        id
        title
        order
        questions: faq_question {
          order
          question {
            question
          }
          answer {
            answer
          }
        }
      }
    }
  }
`
